import React from 'react';

import Image from 'next/image';

import {
  Box,
  CardMedia,
  Divider,
  Typography,
} from '@mui/material';


const Row = ({ rowName, rowValue }) => (
  <Box sx={{ 
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    mt: 1,
    whiteSpace: 'nowrap',
    width: '100%',
    minHeight: 24,
  }}>
    <Typography variant="body2">{rowName}</Typography>
    <Typography
      sx={{
        ml: 2,
        overflow: 'hidden',
      }}
      color="text.secondary"
      textOverflow="ellipsis"
    >{rowValue}</Typography>
  </Box>
)


const CarCard = ({ car }) => {

  return (
    <Box bgcolor="#EFECE8" height="100%">
      {car.picture ? <CardMedia
        component="img"
        height="140"
        image={car.picture}
      /> :
      <Box display="flex" alignItems="center" justifyContent="center" minHeight={150}>
        <Image
          src={`/hub/img/car-logos/big/${car.make}-logo.png`}
          width={150}
          height={112.5}
          alt={`${car.make} logo`}
        />
      </Box>}

      <Box p={2}>
        <Row rowName="Make" rowValue={car.make} />
        <Divider />
        <Row rowName="Model" rowValue={car.model} />
        <Divider />
        <Row rowName="Year" rowValue={car.year} />
        <Divider />
        <Row rowName="Engine" rowValue={car.engine} />
        <Divider />
        <Row rowName="VIN" rowValue={car.vin} />
        <Divider />
      </Box>
    </Box>
  );
}

export default CarCard;
