import React from 'react';

import { useRouter } from 'next/router';
import Image from 'next/image';

import {
  Avatar,
  Badge,
  Button,
  Box,
  Card,
  CardActionArea,
  Collapse,
  Divider,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';

import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import EventIcon from '@mui/icons-material/Event';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import ReplyIcon from '@mui/icons-material/Reply';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import HelpIcon from '@mui/icons-material/Help';
import StarsIcon from '@mui/icons-material/Stars';

import ServiceIcon from './ServiceIcon';
import CarCard from './CarCard';
import UserCard from './UserCard';


const Opportunity = ({ opportunity, index, selected, setOpen }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const router = useRouter();
  
  const open = index === selected;
  
  const toggleOpen = () => {
    setOpen((prevOpen) => prevOpen === index ? null : index);
  }

  const handleReply = (event) => {
    event.stopPropagation();
    router.push(`/create-estimate/${opportunity.id}/`);
  }

  return <>
    {!isMobile ?
    <Card
      sx={{
        my: 3,
        display: {
          xs: 'none',
          md: 'block'
        }
      }}>

      <CardActionArea
        component="div"
        onClick={() => toggleOpen()}
      >
        <Collapse in={!open}>
          <Grid
            container
            sx={{
              py: 1
            }}
          >
            <Grid item md={4} xs={12}>
              <Box display="flex" width="100%" height="100%" alignItems="center" p={2}>
                <Badge
                  color="info"
                  overlap="circular"
                  badgeContent="New"
                  invisible={opportunity.estimate_count > 0}
                >
                  <Avatar
                    src={opportunity.user.picture}
                    sx={{
                      // bgcolor: 'primary.light',
                      width: (theme) => theme.spacing(8),
                      height: (theme) => theme.spacing(8),
                    }}
                  >{opportunity.user.initials}</Avatar>
                </Badge>
                <Box ml={2}>
                  <Typography>{opportunity.user.name}</Typography>
                  {opportunity.existing &&
                    <Typography
                      variant="body2"
                      color="primary.light"
                    >{opportunity.existing}</Typography>}
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box display="flex" width="100%" height="100%" alignItems="center" py={2}>
                <ServiceIcon service={opportunity.job_type.category} width={32} height={32}/>
                <Box ml={2}>
                  <Typography>{ opportunity.job_type.name }</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                height="100%"
                p={2}
              >
                <Image
                  src={`/hub/img/car-logos/big/${opportunity.car.make}-logo.png`}
                  width={64}
                  height={48}
                  alt={`${opportunity.car.make} logo`}
                />
                <Box
                  sx={{
                    fontSize: 12,
                    fontWeight: 300,
                    color: 'text.secondary'
                  }}
                >{ opportunity.timesince }</Box>
              </Box>
            </Grid>
          </Grid>
        </Collapse>

        <Collapse in={open} unmountOnExit>
          <Grid container>
            <Grid item md={3} xs={12}>
              <UserCard user={opportunity.user} />
            </Grid>

            <Grid item md={6} xs={12}>
              <Box display="flex" flexDirection="column" width="100%" height="100%" py={4} px={6}>
                <Box display="flex" alignItems="center">
                  <ServiceIcon service={opportunity.job_type.category} width={42} height={42} />
                  <Box ml={2}>
                    <Typography variant="h6">{ opportunity.job_type.name }</Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    fontSize: 11,
                    // color: 'text.secondary',
                    mt: 2,
                    mb: 0.75,
                    lineHeight: 1.2
                  }}
                  >{opportunity.job_type.helper}</Box>
                <Typography
                  variant="body2"
                  fontStyle="italic"
                  color="text.secondary"
                  sx={{
                    mb: 2,
                    p: 1,
                    border: 1,
                    borderRadius: 2,
                    borderColor: '#eee'
                  }}
                >
                  { opportunity.description || 'No description provided' }
                </Typography>
                <Box display="flex" alignItems="center" py={1}>
                  <EventIcon fontSize="small" style={{ color: '#0497A7' }} />
                  <Box fontSize={14} color="text.secondary" pl={1}>{ opportunity.when }</Box>
                </Box>
                <Box display="flex" alignItems="center" py={1}>
                  <LocationOnIcon fontSize="small" style={{ color: '#0497A7' }} />
                  <Box fontSize={14} color="text.secondary" pl={1}>{ opportunity.where }</Box>
                </Box>
              </Box>
            </Grid>

            <Grid item md={3} xs={12}>
              <CarCard car={opportunity.car} />
            </Grid>
          </Grid>

          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onMouseDown={event => event.stopPropagation()}
                onClick={handleReply}
                startIcon={<ReplyIcon />}
                sx={{ mr: 2 }}
              >Respond</Button>

              {opportunity.estimate_count > 0 ?
                <Typography>
                  <Box component="span" color="primary.light">R {opportunity.avg_estimate}</Box> average from <Box component="span" color="primary.light">{opportunity.estimate_count}</Box> estimate{opportunity.estimate_count > 1 && 's'}
                </Typography> :
                <Typography color="primary.light">Be the first!</Typography>
              }
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center"
              }}
            >
              <WatchLaterIcon fontSize="small" style={{ color: '#0497A7' }} />
              <Box ml={1} fontSize="small" color="text.secondary">{opportunity.created}</Box>
            </Box>
          </Box>
        </Collapse>
      </CardActionArea>

    </Card> :

    <Card
      variant="outlined"
      sx={{ my: 2 }}
    >
      <CardActionArea
        component="div"
        onClick={() => toggleOpen()}
      >
        <Collapse in={!open}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              m: 2
            }}
          >
            <Badge
              color="info"
              overlap="circular"
              badgeContent="New"
              invisible={opportunity.estimate_count > 0}
            >
              <Avatar
                src={opportunity.user.picture}
                sx={{
                  bgcolor: 'primary.light',
                  width: (theme) => theme.spacing(8),
                  height: (theme) => theme.spacing(8),
                }}
              >{opportunity.user.initials}</Avatar>
            </Badge>
            <Box
              sx={{
                ml: 2,
              }}
            >
              <Typography>{opportunity.user.name}</Typography>
              <Typography
                variant="body2"
                fontWeight="fontWeightLight"
              >{ opportunity.where }</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              mx: 2,
              mb: 1,
              display: "flex",
              alignItems: "center"
            }}
          >
            <Box sx={{ p: 1 }}>
              <ServiceIcon service={opportunity.job_type.category} width={48} height={48}/>
            </Box>
            <Box ml={2}>
              <Typography>{ opportunity.job_type.name }</Typography>
              <Typography
                variant="body2"
                fontWeight="fontWeightLight"
                textOverflow="ellipsis"
              >{ opportunity.car.make } { opportunity.car.model }</Typography>
            </Box>
          </Box>
        </Collapse>

        <Collapse in={open}>     
          <Box p={2} display="flex" alignItems="center">
            <Badge
              color="info"
              overlap="circular"
              badgeContent="New"
              invisible={opportunity.estimate_count > 0}
            >
              <Avatar
                src={opportunity.user.picture}
                sx={{
                  bgcolor: 'primary.light',
                  width: (theme) => theme.spacing(8),
                  height: (theme) => theme.spacing(8),
                }}
              >{opportunity.user.initials}</Avatar>
            </Badge>
            <Box ml={2}>
              <Typography>{opportunity.user.name}</Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: '#979797',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 1
                  }}
                >
                  <Box sx={{ mr: 0.25 }}>{opportunity.user.car_count}</Box>
                  <DirectionsCarIcon fontSize="small" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 1
                  }}
                >
                  <Box sx={{ mr: 0.25 }}>{opportunity.user.job_count}</Box>
                  <HelpIcon fontSize="small" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 1
                  }}
                >
                  <Box sx={{ mr: 0.25 }}>{opportunity.user.job_completed_count}</Box>
                  <CheckCircleIcon fontSize="small" />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mr: 1
                  }}
                >
                  <Box sx={{ mr: 0.25 }}>{opportunity.user.review_count}</Box>
                  <StarsIcon fontSize="small" />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box p={2} display="flex" flexDirection="column" bgcolor="secondary.light">
            <Box display="flex" alignItems="center">
              <ServiceIcon service={opportunity.job_type.category} width={40} height={40} />
              <Box ml={2}>
                <Typography variant="body1">{ opportunity.job_type.name }</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                fontSize: 11,
                fontWeight: 300,
                mt: 2,
                mb: 0.75,
                lineHeight: 1.2
              }}
              >{opportunity.job_type.helper}</Box>
            <Typography
              variant="body2"
              fontStyle="italic"
              color="text.secondary"
              sx={{
                mb: 1,
              }}
              lineHeight={1.2}
            >
              &quot;{ opportunity.description || 'No description provided' }&quot;
            </Typography>
            <Box display="flex" alignItems="center" mt={1}>
              <EventIcon fontSize="small" style={{ color: '#0497A7' }} />
              <Box fontSize={12} color="text.secondary" pl={1}>{ opportunity.when }</Box>
            </Box>
            <Box display="flex" alignItems="center" mt={1}>
              <LocationOnIcon fontSize="small" style={{ color: '#0497A7' }} />
              <Box fontSize={12} color="text.secondary" pl={1}>{ opportunity.where }</Box>
            </Box>
          </Box>

          <Box
            sx={{
              p: 2,
            }}>
            {/* TODO: What if car image exists? */}
            <Typography>{ opportunity.car.make } { opportunity.car.model }</Typography>
            <Box fontSize={12} fontWeight="fontWeightLight" textOverflow="ellipsis">{ opportunity.car.year }</Box>

            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              my: 1,
              whiteSpace: 'nowrap',
              width: '100%',
              minHeight: 24,
            }}>
              <Typography variant="body2">Engine</Typography>
              <Typography
                sx={{
                  ml: 2,
                  overflow: 'hidden',
                }}
                color="text.secondary"
                textOverflow="ellipsis"
              >{opportunity.car.engine || 'TBD'}</Typography>
            </Box>
            <Divider />

            <Box sx={{ 
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              my: 1,
              whiteSpace: 'nowrap',
              width: '100%',
              minHeight: 24,
            }}>
              <Typography variant="body2">VIN</Typography>
              <Typography
                sx={{
                  ml: 2,
                  overflow: 'hidden',
                }}
                color="text.secondary"
                textOverflow="ellipsis"
              >{opportunity.car.vin || 'TBD'}</Typography>
            </Box>
            <Divider />

            {opportunity.car.engine_no &&
              <>
                <Box sx={{ 
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  my: 1,
                  whiteSpace: 'nowrap',
                  width: '100%',
                  minHeight: 24,
                }}>
                  <Typography variant="body2">VIN</Typography>
                  <Typography
                    sx={{
                      ml: 2,
                      overflow: 'hidden',
                    }}
                    color="text.secondary"
                    textOverflow="ellipsis"
                  >{opportunity.car.engine_no}</Typography>
                </Box>
                <Divider />
              </>
            }
          </Box>

          <Box width="100%" px={1} pb={1} display="flex" justifyContent="space-between">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: 'text.secondary',
                fontSize: 12
              }}
            >
              <AccessTimeFilledIcon />
              <Box sx={{ ml: 1 }}>{opportunity.timesince}</Box>
            </Box>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onMouseDown={event => event.stopPropagation()}
              onClick={handleReply}
              startIcon={<ReplyIcon />}
            >Respond</Button>
          </Box>
        </Collapse>
      </CardActionArea>
    </Card>
    }
  </>;
}

export default Opportunity;
