import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import {
  BottomNavigation,
  BottomNavigationAction,
  Box
} from '@mui/material';

import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


const SimpleBottomNavigation = ({ value, setValue }) => {
  const router = useRouter();

  console.log('router.asPath', router.asPath)

  useEffect(() => {
    if (["0", "1", "2"].includes(router?.query?.activeTab)) {
      setValue(
        parseInt(router.query.activeTab)
      )
    } else {
      setValue(0)
    }
  }, [router, setValue])

  const handleChange = (index) => {
    router.push(
      {
        pathname: '/',
        query: {
          activeTab: index
        }
      },
      null,
      {
        shallow: true,
      }
    )
    setValue(index);
  }

  return (
    <BottomNavigation
      value={value}
      onChange={(event, newValue) => {
        handleChange(newValue);
      }}
      showLabels
      sx={{
        width: "100vw",
        position: 'fixed',
        bottom: 0,
        zIndex: 1
      }}
      component={Box}
      boxShadow={3}
    >
      <BottomNavigationAction
        label="New"
        icon={<PersonAddIcon />}
        sx={{
          '&.Mui-selected': {
            color: 'primary.light'
          }
        }}
      />
      <BottomNavigationAction 
        label="Leads"
        icon={<RecordVoiceOverIcon />}
        sx={{
          '&.Mui-selected': {
            color: 'primary.light'
          }
        }}
      />
      <BottomNavigationAction
        label="Clients"
        icon={<PeopleAltIcon />}
        sx={{
          '&.Mui-selected': {
            color: 'primary.light'
          }
        }}
      />
    </BottomNavigation>
  );
}

export default SimpleBottomNavigation;
