import { useState } from 'react';

import Head from 'next/head';

import {
  Box,
  Toolbar,
  useMediaQuery
} from '@mui/material';

import TopNav from '../components/TopNav';
import ContactDrawer from '../components/ContactDrawer';
import Contacts from '../components/Contacts';
import Opportunties from '../components/Opportunities';
import BottomNav from '../components/BottomNav';


export default function Index() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [index, setIndex] = useState(0);

  return (
    <Box>
      <Head>
        <title>Opportunties - HUB</title>
      </Head>
      <TopNav />
      
      <Box
        sx={{
          display: {
            xs: 'block',
            md: 'flex'
          }
        }}
      >
        {!isMobile && <ContactDrawer />}

        <Box sx={{ flexGrow: 1 }}>
          <Toolbar />
          {index === 0 && <Opportunties />}
          {index === 1 && <Contacts contactType="leads" />}
          {index === 2 && <Contacts contactType="clients" />}

          {isMobile &&
            <BottomNav
              value={index}
              setValue={setIndex}
            />
          }
        </Box>
      </Box>
    </Box>
  );
}
