import React, { useState } from 'react';

import Image from 'next/image';

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from '@mui/material';

import BuildIcon from '@mui/icons-material/Build';

import useSWRInfinite from 'swr/infinite';

import Loader from './Loader';
import Opportunity from './Opportunity';

import { NextLinkComposed } from './Link';


const Opportunities = () => {
  const [open, setOpen] = useState();

  const getKey = (index) => {
    return `/api/v2/opportunities/?page=${index + 1}`
  }

  const { data, error, size, setSize } = useSWRInfinite(getKey, null, { refreshInterval: 1000 * 60 });
  // TODO handle error

  const results = data ? [].concat(...data) : [];
  const isEmpty = data?.[0]?.length === 0;
  const dataLength = data ? data.length : 0;
  const isLoading = size !== dataLength;
  const isReachingEnd =
    isEmpty || (data && data[data.length - 1]?.length < 20);

  return (
    <Container>
      <Typography
        variant="h2"
        color="primary.light"
        sx={{
          mt: 3
        }}
      >Opportunities</Typography>
      <Typography
        // variant="subtitle"
        color="text.secondary"
        sx={{
          mb: 4,
        }}
      >Jobs posted by people in your area</Typography>

      {results.map((opportunity, index) => (
        <Opportunity
          key={opportunity.id}
          opportunity={opportunity}
          index={index}
          selected={open}
          setOpen={setOpen}
        />
      ))}

      {isEmpty && !isLoading &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: 2,
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: 500
          }}
        >
          <Image width={300} height={202} src="/hub/img/illustrations/welcome-illustration-dark-mode.png" alt="Send estimates" />
          <Typography
            variant="h4"
            color="text.secondary"
            sx={{
              my: 2
            }}
          >No relevant opportunities found</Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ mb: 2 }}
          >Make sure your business profile is complete and accurate</Typography>

          <Button
            variant="contained"
            color="secondary"
            startIcon={<BuildIcon />}
            component={NextLinkComposed}
            to={{ pathname: '/profile/' }}
          >Update Profile</Button>
        </Box>
      }

      {dataLength === 0 && isLoading && <Loader count={10} />}
      {dataLength !== 0 &&
        <Box display="flex" justifyContent="center" py={4}>
          {!isReachingEnd && !isLoading &&
            <Button
              variant="contained"
              color="primary"
              onClick={() => setSize(size + 1)}
            >Load More</Button>
          }
          {isLoading && <CircularProgress />}
        </Box>
      }
    </Container>
  )
}

export default Opportunities;
